.footer_container {
  position: relative;
  padding: 80px 10.5vw;
  background-color: var(--primaryColor);
  overflow: hidden !important;
}

.footer_contact {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #fff;
  border-bottom-right-radius: 1px;
  border-bottom-left-radius: 1px;
  cursor: default;
  transition: all 300ms;
}

.footer_sign {
  display: block;
  /* padding: 60px 0; */
  width: 5vw;
  height: 5vw;
}

.footer_container h1 {
  /* font-size: 72px; */
  font-size: clamp(24px, 4vw, 72px);
  font-weight: bold;
  color: var(--secondaryColor);
  margin-bottom: 10px;
}

.footer_contact:hover {
  box-shadow: inset 0 -5px 0 0 #fff;
}

.footer_middle {
  padding-top: 40px;
  position: relative;
  display: flex;
  width: 100%;
  gap: 50px;
  color: var(--secondaryColor);
}

.footer_column_1 {
  margin-top: auto;
  margin-right: auto;
}

.footer_column_2,
.footer_column_3 {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: space-between;
}

.footer_column_2 .social_link {
  margin-top: auto;
}

.social_link {
  padding-top: 30px;
}

.footer_column_5 {
  display: flex;
  gap: 30px;
  justify-content: space-between;
}

.footer_link_columns {
  gap: 20px;
  display: flex;
  flex-direction: column;
}

.footer_column_3 .social_link {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
}

.footer_middle h3,
.social_link a {
  display: block;
  font-size: clamp(10px, 1.4vw, 24px);
  font-weight: 500;
  color: var(--secondaryColor);
  transition: all 0.3s ease;
}

.footer_middle h3:hover {
  text-decoration: underline;
}

.social_link a:hover {
  font-weight: bold;
}

@media only screen and (max-width: 800px) {
  .footer_middle {
    gap: 20px;
  }
}

@media only screen and (max-width: 600px) {
  
}

@media only screen and (max-width: 500px) {
  .footer_container {
    padding: 60px 30px 60px;
  }
}