/* Navbar styling */
.nav_container {
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    margin-left: auto;
}

/* ----------------- To hide and show navbar ----------------- */
.nav_background {
    position: fixed;
    bottom: 2%;
    right: 32%;
    /* position: fixed;
    top: -30px;
    right: -30px; */
    display: block;
    height: 4rem;
    width: 4rem;
    border-radius: 50%;
    transform: scale(0);
    background-color: var(--secondaryColor);
    transition: transform 0.8s;
}

.nav_active.nav_background {
    transform: scale(80);
}
.nav_menu___hide_animation {
    opacity: 0;
}

.nav_menu.nav_menu___hide {
    display: none;
}

.btn_hovered {
    transition: all 0.3s ease;
    fill: var(--primaryColor);
    transform: rotate(180deg);
}

.btn_un_hovered {
    transition: all 0.3s ease;
    /* transform: rotate(0deg); */
    fill: var(--greyColorDark);
}

/* Navbar Menu and nav links styling */
.nav_menu {
    position: fixed;
    display: block;
    margin-left: auto;
    transition: opacity 0.5s ease;
    top: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
    z-index: 5;
    /* background-color: var(--primaryColor); */
    background-color: var(--secondaryColor);
    padding: 80px 10.5vw 50px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.nav_menu ul {
    position: relative;
    display: flex;
    z-index: 4;
    color: var(--greyColor);
    flex-direction: column;
    width: 100%;
    padding: 30px 0 30px;
    background-color: inherit;
    /* background-color: var(--primaryColor); */
    list-style-type: none;
    gap: 30px;
    /* gap: 3vw; */
}

.nav_menu ul li a {
    /* font-size: calc(14px + 1.4vw); */
    /* color: var(--greyColorDark); */
    color: var(--primaryColor);
    /* font-size: 72px; */
    /* font-size: calc(18px + 2.3vw); */
    /* font-size: var(--fontSizeHead); */
    font-size: clamp(24px, 5vw, 72px);
    font-weight: 600;
    transition: color 0.5s ease;
}

.nav_menu ul li .drop_link_active {
    color: var(--primaryColor);
}

.nav_menu ul li {
    counter-increment: listItemNumber;
    /* padding: 15px 20px; */
    /* padding: 0 0 20px; */
    /* padding: 20px 0; */
}

/* .nav_menu ul li:nth-child(1) {
    padding: 0 0 20px;
} */

.nav_menu ul li::before {
    font-size: 12px;
    /*in mobile*/
    content: counters(listItemNumber, ".", decimal-leading-zero) " ";
    color: var(--primaryColor);
    margin-right: 30px;
}

.nav_menu ul li:nth-child(1)::before {
    margin-right: 32px;
}

.nav_menu ul li span {
    display: inline-block;
    width: 80%;
}

.nav_link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--greyColor);
}

.nav_arrow {
    width: 30px;
    height: 30px;
    color: var(--greyColor);
}

.dropdown {
    position: relative;
    display: flex;
    opacity: 1;
    width: 100%;
    flex-direction: column;
    top: 0;
    left: 0;
    padding: 10px 0 0 38px;
    color: var(--greyColorDark) !important;
    transition: unset;
    gap: 20px;
}

.sub_dropdown {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 10px 0 0 38px;
    gap: 10px;
}

.dropdown .sub_dropdown {
    padding: 10px 0 0 0px;
    gap: 5px;
}

.dropdown a {
    /* margin: 20px 0 0; */
    font-size: 26px !important;
    /* font-weight: 600 !important; */
}

.sub_dropdown a {
    /* margin: 0 0 20px !important; */
    font-weight: normal !important;
    font-size: 18px !important;
}

.dropdown_show {
    display: block;
    opacity: 1;
}

.dropdown_hide {
    opacity: 0;
    display: none;
}

.dropdown .nav_menu___line_horizonetal {
    width: 0;
    animation: lineShowInside 1s ease forwards;
}

@keyframes lineShowInside {
    0% {
        width: 0;
    }

    100% {
        width: 80%;
    }
}

.nav_menu___line_horizonetal {
    border: none;
    height: 1.5px;
    width: 0;
    background-color: var(--greyColorDark);
    animation: lineShow 1s ease forwards;
}

@keyframes lineShow {
    0% {
        width: 0;
    }

    100% {
        width: 85%;
    }
}

/* Navbar end two links separate styling to match design */
.nav_menu___inline_links {
    position: relative;
    display: flex;
    width: 90%;
    justify-content: space-between;
    /* margin-bottom: 15px; */
}

.nav_menu___inline_links a {
    /* font-size: 60px !important; */
    font-size: clamp(20px, 4.5vw, 60px) !important;
}

.nav_menu___flex_grid::before {
    content: " ";
    display: block;
    width: 0;
    top: 0;
    height: 1.5px;
    background: var(--greyColorDark);
    animation: borderShow 1s ease forwards;
}

.nav_menu___flex_grid::after {
    content: " ";
    display: block;
    width: 0;
    top: 0;
    height: 1.5px;
    background: var(--greyColorDark);
    animation: borderShow 1s ease forwards;
}

@keyframes borderShow {
    0% {
        width: 0;
    }

    100% {
        width: 100%;
    }
}

/* Navbar Footer link styling */
.nav_menu___footer {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0 0;
}

.nav_menu___footer a,
.nav_menu___footer h4 {
    font-size: 15px !important;
    font-weight: 500;
    color: var(--primaryColor);
}

.nav_menu___footer a:hover {
    font-weight: bold !important;
}

.nav_menu___footer div a {
    padding-right: 20px;
}

@media only screen and (max-width: 600px) {
    .nav_menu {
        padding: 60px 40px 80px;
    }

    .nav_menu ul {
        gap: 20px;
    }

    .nav_menu ul li::before {
        font-size: 12px;
        margin-right: 20px;
    }

    .nav_menu ul li:nth-child(1)::before {
        margin-right: 22px;
    }

    .nav_menu ul li span {
        width: 80%;
    }

    .nav_menu ul li a {
        /* font-size: 32px; */
        font-size: clamp(18px,6vw,36px);
    }

    .dropdown a {
        /* margin: 20px 0 0; */
        font-size: 18px !important;
        color: var(--primaryColor) !important;
        /* font-weight: 600 !important; */
    }
    
    .sub_dropdown a {
        color: var(--greyColorDark) !important;
        /* margin: 0 0 20px !important; */
        font-weight: 600 !important;
        font-size: 12px !important;
    }

    .nav_menu___inline_links {
        flex-direction: column;
        gap: 20px;
    }

    .dropdown {
        gap: 15px;
    }

    .nav_menu___inline_links a {
        font-weight: 600 !important;
        font-size: clamp(18px,6vw,26px) !important;
    }
}