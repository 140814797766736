@font-face {
  font-family: 'Manrope';
  src: url('../src/assets/font/Manrope-VariableFont_wght.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
}

:root {
  --primaryColor: #000;
  --secondaryColor: #fff;
  --greyColor: #9A9A9A;
  --greyColorDark: #555555;

  --fontSizeHead: clamp(24px, 4vw, 72px);
  --fontSizeHead2: clamp(24px, 3vw, 72px);
  --fontSizeHead3: clamp(18px, 2.5vw, 72px);

  --fontSizeSubHead1: clamp(16px, 2vw, 36px);
  --fontSizeSubHead2: clamp(14px, 1.8vw, 36px);
  --fontSizeSubHead3: clamp(18px, 2vw, 48px);

  --fontSizeBody: clamp(12px, 1.4vw, 24px);
  --fontSizeBody2: clamp(12px, 1.2vw, 24px);
  
  --fontSizeEmail: clamp(16px, 1.8vw, 30px);
}

*,
*:after,
*:before {
  margin:0;
  padding:0;
  box-sizing:border-box;
  font-family: 'Manrope', sans-serif;
  /* -webkit-overflow-scrolling: touch; */
}


/* html {
  scroll-behavior: smooth;
} */

svg {
  display: block;
  /* background-size: 100% 100%; */
}

body {
  background-color: var(--primaryColor);
  overflow-x: hidden;
}

/*width*/
::-webkit-scrollbar {
  width: 5px;
  color: #000;
}

/*track*/
::-webkit-scrollbar-track {
  background-color: #000;
}

/*handle*/
::-webkit-scrollbar-thumb {
  background-color: #9a9a9a;
  border-radius: 3px;
}

/*handle on hover*/
::webkit-scrollbar-thumb:hover {
  background-color: #9a9a9a;
}

a {
  display: inline-block;
  color: inherit;
  text-decoration: none;
  cursor: pointer !important;
}

button {
  cursor: pointer !important;
  border: none;
  background: none;
}

/* Navbar styling */
header {
  position: fixed;
  background-color: transparent;
  top: 0;
  right: 0;
  padding: 25px 80px 0 0;
  z-index: 8;
}

nav a {
  letter-spacing: 2px;
}

nav {
  position: relative;
  background-color: transparent;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  z-index: 8;
  justify-content: center;
  align-items: center;
}

.component_header {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 20px;
  background-color: none;
}

.component_header a {
  position: fixed;
  display: block;
  z-index: 1;
  left: 6vw;
}

.n_logo {
  /* position: fixed; */
  /* left: 110px; */
  display: block;
  width: 30px;
  background-color: none;
}

.component_h_link {
  position: relative;
  display: block;
  /* margin-top: 50px; */
  padding: 0 10.5vw;
  font-family: "Manrope", sans-serif;
  font-size: 36px;
  font-weight: 600;
  color: #9a9a9a;
  background-color: none;
}

.vertical_line {
  transform: scaleY(5) rotate(90deg);
  color: var(--greyColor);
}

.normal_btn___light {
  position: relative;
  display: block;
  background-color: var(--secondaryColor);
  color: var(--primaryColor);
  border: 1px solid var(--primaryColor);
  border-radius: 100px;
  padding: 12px 50px;
  font-size: clamp(14px, 1.2vw, 20px);
  font-weight: 600;
  /* letter-spacing: 2px; */
  transition: all 0.3s ease !important;
  text-align: center;
}

.normal_btn___dark {
  position: relative;
  display: block;
  background-color: var(--primaryColor);
  color: var(--secondaryColor);
  border: 1px solid var(--secondaryColor);
  border-radius: 100px;
  padding: 12px 50px;
  font-size: clamp(14px, 1.2vw, 20px);
  font-weight: 600;
  /* letter-spacing: 2px; */
  transition: all 0.3s ease !important;
  text-align: center;
}

.normal_btn___light:hover {
  background-color: var(--primaryColor);
  color: var(--secondaryColor);
}

.normal_btn___dark:hover {
  background-color: var(--secondaryColor);
  color: var(--primaryColor);
}

.email_text_light,
.email_text_dark {
  font-size: var(--fontSizeEmail);
  font-weight: bold;
}

.email_text_light {
  width: fit-content;
  border-bottom: 1px solid var(--secondaryColor);
  color: var(--secondaryColor);
}

.email_text_dark {
  width: fit-content;
  color: var(--primaryColor);
  border-bottom: 1px solid var(--primaryColor);
}

.email_text_dark:hover {
  box-shadow: inset 0 -2px 0 0 #000;
}

.email_text_light:hover {
  box-shadow: inset 0 -2px 0 0 #fff;
  /* text-decoration: underline; */
}

.menu_active {
  top: 0;
}

.upscroll {
  /* background-color: rgba(10, 10, 10, 0.95); */
  /* height: 70px; */
  /* top: 0; */
  transform: translateY(0);
  /* box-shadow: 0 5px 40px 1px var(--fontHeadColor); */
}
.downscroll {
  /* height: 70px; */
  transform: translateY(100vw);
}

@media only screen and (max-width: 1210px) {
  header {
    padding: 25px 40px 0 0;
  }
}

@media only screen and (max-width: 1050px) {
  .component_header a {
    left: 40px;
  }
}

@media only screen and (max-width: 800px) {
  header {
    position: fixed;
    bottom: 0;
    top: unset;
    left: 0;
    padding: 0;
    /* height: 100%; */
    transition: all 0.5s ease;
  }
  nav {
    width: unset;
    height: unset;
  }
}

@media only screen and (max-width: 750px) {
  .component_h_link {
    /* padding: 0 80px; */
    font-size: 30px;
  }

  .normal_btn___light,
  .normal_btn___dark {
    padding: 10px 30px;
    /* font-size: 13px; */
  }
}

@media only screen and (max-width: 700px) {
  /* header {
    padding: 30px 20px 0 0;
  } */

  .component_header a {
    left: 20px;
  }
}

@media only screen and (max-width: 500px) {

  /* header {
    padding: 20px 18px 0 0;
  } */

  .component_h_link {
    font-size: 20px;
  }

  .component_header a {
    left: 3.5vw;
  }

  .n_logo {
    width: 20px;
  }
}

@media only screen and (max-width: 450px) {
  .component_header a {
    left: 2.5vw;
  }
}

/* loader styling */
.loader_screen {
  position: fixed;
  width: 100%;
  height: 100%;
  /* background: url("./assets/north.gif") center no-repeat; */
  background-color: var(--primaryColor);
  /* background-color: var(--secondaryColor); */
  z-index: 100;
}

.loader_screen img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: block;
  width: 300px;
  height: 300px;
  object-fit: contain;
}

.loader {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 100px;
  height: 100px;
  border: 5px solid var(--primaryColor);
  border-bottom-color: transparent;
  border-radius: 50%;
  display: block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes fadeout {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
/* 
.image_slider {
  position: relative;
  overflow: hidden;
}

.image_slide___container {
  position: relative;
  overflow-x: auto;
  display: flex;
  gap: 10px;
}

.image_slide___container::-webkit-scrollbar {
  display: none;
}

.image_slide___container img {
  display: block;
  width: 100%;
  height: 500px;
  object-fit: cover;
} */

/* .explore_section .btn_nav {
  background-color: #000;
  width: 50px;
  height: 50px;
  border-radius: 100px;
  padding-top: 14px;
} */

.section_padding {
  position: relative;
  padding: 100px 10.5vw;
  background-color: var(--secondaryColor);
  overflow: hidden;
}

#Gol img,#Narrative img, #Dhajji img,#Shaheed img, #Naggar img {
  /* opacity: 0; */
  /* width: 50%; */
  object-fit: contain;
}

#Jana img {
  /* opacity: 0; */
  /* width: 50%; */
  object-fit: cover;
  object-position: 50% 100%;
}

.shift_mid_right img{
  object-fit: cover !important;
  object-position: 71% !important;
}

.shift_mid_left img{
  object-fit: cover !important;
  object-position: -76px !important;
}
.shift_complete_left img{
  object-fit: cover !important;
  object-position: 0% !important;
}
.shift_complete_right img{
  object-fit: cover !important;
  object-position: 85% !important;
}

.shift_top img {
  object-fit: cover !important;
  object-position: 0% 30% !important;
}
.shift_mid_top img {
  object-fit: cover !important;
  object-position: 0% 40% !important;
}
.shift_middle img {
  object-fit: cover !important;
  object-position: 0% 50% !important;
}

/* .shift_mid_left_2 img{
  object-fit: cover !important;
  object-position: 70% !important;
} */
.shift_mid_left_2 img{
  object-fit: cover !important;
  object-position: -215px !important;
}

@media only screen and (max-width: 450px) {
  /* .shift_mid_left_2 img{
    object-fit: cover !important;
    object-position: 60% !important;
  } */
  
  .shift_mid_left img{
    object-fit: cover !important;
    object-position: 22.5% !important;
  }
  
}
@media only screen and (max-width: 400px) {
  .shift_complete_right img{
    object-fit: cover !important;
    object-position: 102% !important;
  }
  .shift_mid_left_2 img{
    object-fit: cover !important;
    object-position: 52% !important;
  }
  .shift_mid_left img{
    object-fit: cover !important;
    object-position: 19.5% !important;
  }
  
}

/* .ytp-cued-thumbnail-overlay-image {
  background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  background-position: left !important;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
} */