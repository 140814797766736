/* Navbar styling */
.nav_container {
    position: relative;
    display: block;
    margin-left: auto;
}

/* Navbar North logo container and each logo's part image styling */
.nav_header {
    position: fixed;
    left: 40px;
    top: 30px;
    z-index: 5;
}

.nav_header a {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.n_logo {
    position: relative;
    display: block;
    width: 30px;
    z-index: 5;
}

.orth_logo {
    position: relative;
    display: block;
    width: 80px;
    z-index: 5;
}

/* ----------------- To hide and show navbar ----------------- */
.nav_menu___hide_animation {
    opacity: 0;
    /* transform: scale(0); */
    /* border-radius: 50%; */
}

.nav_background {
    position: fixed;
    top: -30px;
    right: -30px;
    display: block;
    height: 4rem;
    width: 4rem;
    border-radius: 50%;
    transform: scale(0);
    background-color: var(--primaryColor);
    transition: transform 0.8s;
}

.nav_active.nav_background {
    transform: scale(80);
}

.nav_menu.nav_menu___hide {
    display: none;
}

.btn_hovered {
    transition: all 0.3s ease;
    fill: var(--secondaryColor);
    transform: rotate(180deg);
}

.btn_un_hovered {
    transition: all 0.3s ease;
    /* transform: rotate(0deg); */
    fill: var(--greyColorDark);
}

/* Navbar Menu and nav links styling */
.nav_menu {
    position: fixed;
    display: block;
    margin-left: auto;
    transition: opacity 0.5s ease;
    top: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
    z-index: 3;
    background-color: var(--primaryColor);
    padding: 100px 10.5vw 50px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.nav_menu ul {
    position: relative;
    display: flex;
    z-index: 4;
    color: var(--greyColor);
    flex-direction: column;
    width: 100%;
    padding: 50px 0 30px;
    background-color: var(--primaryColor);
    list-style-type: none;
    gap: 30px;
    /* gap: 3vw; */
}

.nav_menu ul li a {
    /* font-size: calc(14px + 1.4vw); */
    color: var(--greyColor);
    /* font-size: 72px; */
    /* font-size: calc(18px + 2.3vw); */
    /* font-size: var(--fontSizeHead); */
    font-size: clamp(24px, 5vw, 72px);
    font-weight: 600;
    transition: color 0.5s ease;
}

.nav_menu ul li .drop_link_active {
    color: var(--secondaryColor);
}

.nav_menu ul a:hover {
    color: var(--secondaryColor);
}

.nav_menu ul li {
    counter-increment: listItemNumber;
    /* padding: 15px 20px; */
    /* padding: 0 0 20px; */
    /* padding: 20px 0; */
}

/* .nav_menu ul li:nth-child(1) {
    padding: 0 0 20px;
} */

.nav_menu ul li::before {
    font-size: 12px;
    /*in mobile*/
    content: counters(listItemNumber, ".", decimal-leading-zero) " ";
    color: var(--secondaryColor);
    margin-right: 30px;
}

.nav_menu ul li:nth-child(1)::before {
    margin-right: 32px;
}

.nav_menu ul li span {
    display: inline-block;
    width: 80%;
}

.nav_link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--greyColor);
}

.nav_arrow {
    width: 30px;
    height: 30px;
    color: var(--greyColor);
}

.dropdown {
    position: relative;
    display: flex;
    opacity: 1;
    width: 100%;
    flex-direction: column;
    top: 0;
    left: 0;
    padding: 10px 0 0 47px;
    color: var(--greyColorDark) !important;
    transition: unset;
    gap: 20px;
}

.sub_dropdown {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 10px 0 0 47px;
    gap: 10px;
}

.dropdown .sub_dropdown {
    padding: 0 0 0 0px;
    gap: 5px;
}

.dropdown a {
    /* margin: 20px 0 0; */
    font-size: 26px !important;
    /* font-weight: 600 !important; */
}

.sub_dropdown a {
    /* margin: 0 0 20px !important; */
    font-weight: normal !important;
    font-size: 18px !important;
}

.dropdown_show {
    display: block;
    opacity: 1;
}

.dropdown_hide {
    opacity: 0;
    display: none;
}

.dropdown .nav_menu___line_horizonetal {
    width: 0;
    animation: lineShowInside 1s ease forwards;
}

@keyframes lineShowInside {
    0% {
        width: 0;
    }

    100% {
        width: 80%;
    }
}

.nav_menu___line_horizonetal {
    border: none;
    height: 1.5px;
    width: 0;
    background-color: var(--greyColor);
    animation: lineShow 1s ease forwards;
}

@keyframes lineShow {
    0% {
        width: 0;
    }

    100% {
        width: 85%;
    }
}

/* Navbar end two links separate styling to match design */
.nav_menu___inline_links {
    position: relative;
    display: flex;
    width: 80%;
    justify-content: space-between;
    /* margin-bottom: 15px; */
}

.nav_menu___inline_links a {
    /* font-size: 60px !important; */
    font-size: clamp(20px, 4vw, 60px) !important;
}

.nav_menu___flex_grid::before {
    content: " ";
    display: block;
    width: 0;
    top: 0;
    height: 1.5px;
    background: var(--greyColor);
    animation: borderShow 1s ease forwards;
}

.nav_menu___flex_grid::after {
    content: " ";
    display: block;
    width: 0;
    top: 0;
    height: 1.5px;
    background: var(--greyColor);
    animation: borderShow 1s ease forwards;
}

@keyframes borderShow {
    0% {
        width: 0;
    }

    100% {
        width: 100%;
    }
}

/* Navbar Footer link styling */
.nav_menu___footer {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
}

.nav_menu___footer a,
.nav_menu___footer h4 {
    font-size: 15px !important;
    font-weight: 500;
    color: var(--secondaryColor);
}

.nav_menu___footer a:hover {
    font-weight: bold !important;
}

.nav_menu___footer div a {
    padding-right: 20px;
}

/* ----------------- Hamburger button styling and animation ----------------- */
.nav_menu___btn {
    position: relative;
    display: block;
    /* margin-left: auto; */
    /* padding: 12px 10px; */
    width: 50px;
    padding: 10px;
    z-index: 5;
    cursor: pointer;
    /* background-color: var(--primaryColor); */
    /* border-radius: 100px; */
}

.nav_menu___btn:after,
.nav_menu___btn:before,
.nav_menu___btn div {
    background-color: var(--greyColor);
    /* background-color: var(--primaryColor); */
    content: '';
    width: 30px;
    display: block;
    /* z-index: 5; */
    height: 3px;
    /* transition: all .2s ease-in-out; */
    transition: all 1s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.change_color.nav_menu___btn:after,
.change_color.nav_menu___btn:before,
.change_color.nav_menu___btn div {
    background-color: var(--primaryColor);
}
.nav_menu___btn div {
    margin: 6px 0;
}

.btn_close.change_color.nav_menu___btn:after,
.btn_close.change_color.nav_menu___btn:before,
.btn_close.change_color.nav_menu___btn div {
    background-color: var(--greyColor);
}

.btn_close.nav_menu___btn:before {
    transform: translateY(8.5px) rotate(45deg);
}

.btn_close.nav_menu___btn:after {
    transform: translateY(-8.5px) rotate(-45deg);
}

.btn_close.nav_menu___btn div {
    opacity: 0;
}


@media only screen and (min-width: 875px) {
    .nav_menu ul li a {
        font-size: clamp(24px, 4vw, 72px);
    }

    .nav_menu___inline_links a {
        /* font-size: 60px !important; */
        font-size: clamp(20px, 3.5vw, 60px) !important;
    }
}

@media only screen and (min-width: 1010px) {
    .nav_menu___flex_grid {
        overflow-x: hidden;
    }

    .nav_menu ul {
        width: 70%;
    }

    .nav_menu ul li a {
        font-size: clamp(24px, 4vw, 72px);
    }

    .nav_menu___inline_links a {
        /* font-size: 60px !important; */
        font-size: clamp(20px, 3.5vw, 60px) !important;
    }

    .nav_menu___line_vertical {
        position: absolute;
        display: block;
        width: 2px;
        height: 100%;
        top: 0;
        margin: 0 90%;
        background-color: #9a9a9a;
        border: none;
    }

    .dropdown_show {
        display: block;
        animation: dropdownShow 0.3s ease forwards;
    }

    .dropdown a:hover,
    .sub_dropdown a:hover {
        transition: transform 0.3s ease;
        transform: translateY(-5px);
        color: var(--secondaryColor);
    }

    @keyframes dropdownShow {
        0% {
            opacity: 0;
            transform: translateX(-100px);
        }

        50% {
            transform: translateX(-100px);
            opacity: 0;
        }

        100% {
            opacity: 1;
            transform: translateX(0px);
            display: block;
        }
    }

    .nav_dropdown {
        width: 60%;
        position: absolute;
        right: 0px;
        top: 50px;
        margin-left: auto;
        margin-right: -48%;
    }

    .dropdown a {
        /* margin: 20px 0 0; */
        font-size: 26px !important;
        /* font-weight: 600 !important; */
    }

    .sub_dropdown a {
        /* margin: 0 0 20px !important; */
        font-weight: normal !important;
        font-size: 20px !important;
    }

    .dropdown .sub_dropdown a {
        font-size: 14px !important;
    }

    .dropdown .nav_menu___line_horizonetal {
        width: 0;
        animation: lineShowInside 1s ease forwards;
    }

    @keyframes lineShowInside {
        0% {
            width: 0;
        }

        100% {
            width: 100%;
        }
    }
}

@media only screen and (min-width: 1050px) {
    .nav_header {
        position: fixed;
        left: 6vw;
        top: 30px;
        z-index: 5;
    }
}

@media only screen and (min-width: 1080px) {
    .nav_menu {
        padding: 100px 10.5vw 0;
    }

    .nav_menu ul {
        /* gap: 20px; */
        gap: 2vw;
    }

    .nav_menu ul li a {
        font-size: clamp(24px, 3vw, 72px);
    }

    .nav_menu___inline_links a {
        /* font-size: 60px !important; */
        font-size: clamp(20px, 2.5vw, 60px) !important;
    }

    .dropdown {
        gap: 30px;
    }

    .dropdown .sub_dropdown {
        padding-top: 15px;
    }

    .sub_dropdown {
        gap: 20px;
    }
}