.appbar_container {
    position: fixed;
    bottom: 20px;
    /* top: 0; */
    display: flex;
    background-color: var(--primaryColor);
    border: 1.2px solid var(--secondaryColor);
    /* width: 200px; */
    min-width: 150px;
    /* margin: 0 30px; */
    border-radius: 50px;
    gap: 4vw;
    height: 50px;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    padding: 5px;
    transition: 0.2s all linear;
    z-index: 7;
    /* padding: 5px 2vw; */
    /* padding: 10px 30px; */
}

.active_link {
    display: inline-block;
    position: relative;
    color: var(--primaryColor);
    font-size: clamp(12px, 5vw, 18px);
    letter-spacing: 2px;
    font-weight: bold;
}

.active {
    /* width: 100%; */
    background-color: var(--secondaryColor) !important;
    transition: all 1s ease;
}

.logo {
    height: 100%;
    padding: 0px 10px;
    background-color: rgb(39, 39, 39);
    /* padding: 8px 4vw 5px; */
    border-radius: 100px;
    display: flex;
    align-items: center;
}

.active span {
    /* line-height: 4.5vw; */
    padding-left: 1px;
    color: var(--greyColor);
    font-size: 20px;
    font-weight: 600;
    /* font-size: clamp(14px, 2vw, 28px); */
    letter-spacing: 1px;
}

.n_logo {
    /* width: 5vw; */
    width: 20px;
    height: 20px;
    z-index: 10;
}

.n_logo img {
    display: block;
    height: 100%;
    width: 100%;
}

.navbar {
    height: 100%;
}

.navbar_cont_btn {
    position: relative;
    padding: 0 10px;
    /* padding: 4px 9px 0px 10px; */
    display: flex;
    width: 100%;
    height: 100%;
    margin-left: auto;
    justify-content: flex-end;
    align-items: center;
    /* gap: 10px; */
    border-radius: 300px;
    /* z-index: 8; */
    background-color: rgb(39, 39, 39);
}

.navbar_cont_btn .active_link {
    margin-left: 10px;
}

.location {
    font-size: 20px;
    font-weight: 600;
    color: var(--greyColor);
    z-index: 10;
}

.menu_btn_cont{
    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* width: 100%; */
    /* height: 100%; */
    gap: 4px;
}

.menu_btn {
    position: relative;
    display: block;
    background-color: var(--greyColor);
    width: 20px;
    height: 3px;
    transition: all 1s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    z-index: 10;
    flex: 1 1 1;
    /* flex-basis: 1 !important; */
}

.close_menu___btn:nth-child(1) {
    transform: translateY(7px) rotate(45deg);
}
.close_menu___btn:nth-child(2) {
    opacity: 0;
}
.close_menu___btn:nth-child(3) {
    transform: translateY(-7px) rotate(-45deg);
}

.navbar_hidden {
    transform: translateY(-100%);
  }